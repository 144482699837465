'use client'
import {createContext, use, useState} from 'react'
import {type SingleLineStructuredTextFragment} from '~/cms/components/SingleLineStructuredText/_generated/fragment.graphql'

type Disclaimer = {
  id: string
  text: SingleLineStructuredTextFragment['text']
  label: string
  kebabLabel: string
  number: number
}
type DisclaimerContextProps = {
  disclaimers: Record<string, Disclaimer>
  addDisclaimer: (disclaimer: Omit<Disclaimer, 'number'>) => void
}
const DisclaimerContext = createContext<DisclaimerContextProps>(
  {} as DisclaimerContextProps
)

export function useDisclaimerContext() {
  const context = use(DisclaimerContext)
  if (!context) {
    throw new Error('useDisclaimerContext must be used within a DisclaimerProvider')
  }
  return context
}

export default function DisclaimerProvider({children}: {children: React.ReactNode}) {
  const [disclaimers, setDisclaimers] = useState<
    DisclaimerContextProps['disclaimers']
  >({})

  function addDisclaimer(disclaimer: Omit<Disclaimer, 'number'>) {
    setDisclaimers(prev => {
      if (prev[disclaimer.id]) {
        return prev
      }
      return {
        ...prev,
        [disclaimer.id]: {
          ...disclaimer,
          number: Object.keys(prev).length + 1,
        },
      }
    })
  }

  return (
    <DisclaimerContext value={{disclaimers, addDisclaimer}}>
      {children}
    </DisclaimerContext>
  )
}
