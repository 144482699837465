'use client'
import {type ThemeSwitcherFragment} from '~/cms/components/DatoThemeSwitcher/_generated/fragments.graphql'
import {grid, gridItem} from '~/design-system/foundations'
import {HgFooter, HgThemeSwitcher} from '~/design-system/hg/components'
import {type Theme, themeToClassName} from '~/design-system/hg/tokens/colors'
import {cn} from '~/design-system/utils'
import MWFooter from '~/mercuryWebCompat/_components/Footer'
import {type FooterFragment} from './_generated/fragments.graphql'
import {useDisclaimerContext} from '~/cms/pages/CMSPageLayout/_components/DisclaimerProvider'

type FooterProps = {
  data: FooterFragment | undefined | null
  theme: ThemeSwitcherFragment | undefined | null
}
export default function Footer(props: FooterProps) {
  const {theme, data} = props
  const {disclaimers} = useDisclaimerContext()
  const themeName = theme ? (theme.name as Theme) : 'darkPurple'

  // return current footer if no footer data exists in dato for PageVariant
  if (!data) {
    return (
      <HgThemeSwitcher theme={themeName} applyGlobally={false}>
        <div className={cn(themeToClassName[themeName], 'bg-background-default')}>
          <div
            className={grid({
              className: cn(
                'mx-auto pt-s12 text-text-default arcadia-body-3',
                Object.values(disclaimers).length === 0 ? 'pb-s12' : 'pb-s9'
              ),
            })}
          >
            <MWFooter
              noBorder
              noDisclaimer
              newStyles
              className={gridItem({
                size: 'max',
              })}
            />
          </div>
        </div>
      </HgThemeSwitcher>
    )
  }

  return (
    <HgThemeSwitcher theme={themeName} applyGlobally={false}>
      <div className={cn(themeToClassName[themeName], 'bg-background-default')}>
        <div
          className={grid({
            className: cn(
              'mx-auto w-full pt-s12 text-text-default text-text-default arcadia-body-3',
              Object.values(disclaimers).length === 0 ? 'pb-s12' : 'pb-s9'
            ),
          })}
        >
          <HgFooter sections={data.sections} />
        </div>
      </div>
    </HgThemeSwitcher>
  )
}
