'use client'

import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import {useEffect, useRef, useState} from 'react'
import {RemoveScroll} from 'react-remove-scroll'
import {
  HgHamburger,
  HgIcon,
  HgNavigationFooter,
  HgNavigationItemLink,
  HgNavigationSubMenu,
  type HgNavigationMenuProps,
} from '~/design-system/hg/components'
import {cn} from '~/design-system/utils'
import {hgAccordionVariants} from '../../HgAccordion'
import {type HgMobileNavProps} from '../types'
import CTAButtons from './CTAButtons'
import HgNavigationWordmark from './HgNavigationWordmark'
import {getPreventHoverProps} from '../utils'
import mobileNavVariants from '../_variants/mobileNavVariants'

function HgMobileContent({
  subMenus,
  setNavValue,
  closeNav,
  menuTitle,
  footerProps,
}: Pick<HgNavigationMenuProps, 'subMenus' | 'menuTitle' | 'footerProps'> & {
  setNavValue: (value: string) => void
  closeNav: () => void
}) {
  function closeSubNav() {
    setNavValue('')
  }
  return (
    <NavigationMenu.Content
      onInteractOutside={e => {
        e.preventDefault()
      }}
      {...getPreventHoverProps()}
    >
      <div className="flex h-navbar-height items-center px-s5 py-16">
        <button
          onClick={closeSubNav}
          className="flex gap-s2 rounded text-text-default outline-2 outline-offset-4 outline-border-focus hover:text-text-subdued focus-visible:outline active:text-text-emphasized"
        >
          <HgIcon iconType="chevron-left" size="medium" />
          <span className="arcadia-ui-1">Main menu</span>
        </button>
      </div>
      <div className="flex max-h-screen-without-navbar flex-col gap-32 overflow-y-auto px-s5 pb-s6">
        <span className="border-t border-border-frosted pt-32 text-text-default arcadia-subheading-5">
          {menuTitle}
        </span>
        {subMenus.map(
          (subMenu, index) =>
            subMenu && (
              <HgNavigationSubMenu key={index} closeNav={closeNav} {...subMenu} />
            )
        )}
        {footerProps && (
          <HgNavigationFooter
            orientation="vertical"
            layout={footerProps.layout}
            footerLinks={footerProps.footerLinks}
            closeNav={closeNav}
          />
        )}
      </div>
    </NavigationMenu.Content>
  )
}

export default function HgMobileNav({
  navigationItems,
  hideEscapeLinks,
  handleDownloadAnalyticsEvent,
  primaryButtonProps,
  isScrolled,
  className,
}: HgMobileNavProps) {
  const {root, content, bar} = mobileNavVariants()
  const {accordionItem, accordion, accordionTrigger} = hgAccordionVariants()

  // There are different easing functions for entering and exiting the nav
  const [navTransitionDirection, setNavTransitionDirection] = useState<
    'enter' | 'exit'
  >('enter')
  const [navValue, setNavValue] = useState('')
  const [navOpen, setNavOpen] = useState(false)
  const [isResizing, setIsResizing] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout>(undefined)

  /*
   * Prevent transition animation from running when resizing the window.
   * Necessary because the menu drawer opens from the right on tablet, but from the left on mobile
   * Without this, the drawer would animate across the screen when resizing the window and crossing breakpoints
   */
  useEffect(() => {
    const resizeHandler = () => {
      clearTimeout(timeoutRef.current)
      setIsResizing(true)
      timeoutRef.current = setTimeout(() => {
        setIsResizing(false)
      }, 100)
    }
    window.addEventListener('resize', resizeHandler)
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const closeNav = () => {
    setNavOpen(false)
  }

  function toggleNav() {
    setNavOpen(prev => !prev)
  }

  return (
    <>
      <div
        className={cn(
          'pointer-events-none absolute inset-0 z-[5] hidden h-screen bg-surface-frosted-active opacity-0 transition-opacity duration-300 md:block lg:hidden',
          navOpen && 'pointer-events-auto opacity-100'
        )}
        onClick={toggleNav}
        aria-hidden
      />

      <div className={bar({isScrolled, className})}>
        <div className="flex flex-1 justify-between">
          <HgNavigationWordmark
            handleDownloadAnalyticsEvent={handleDownloadAnalyticsEvent}
          />
          <div className={cn('z-[3] flex items-center gap-16')}>
            <div className="hidden md:flex">
              <CTAButtons primaryButtonProps={primaryButtonProps} />
            </div>
            {!hideEscapeLinks && (
              <button
                className="group rounded outline-2 outline-offset-4 outline-border-focus focus-visible:outline"
                onClick={toggleNav}
              >
                <span className="sr-only">
                  {navOpen ? 'Close Menu' : 'Open Menu'}
                </span>
                <HgHamburger navOpen={navOpen} />
              </button>
            )}
          </div>
        </div>
        {/* ------------------------------------------ */}
        <NavigationMenu.Root
          value={navValue}
          onValueChange={setNavValue}
          className={root({
            transitionDirection: navTransitionDirection,
            navOpen,
            disableAnimation: isResizing,
            className: 'pt-navbar-height ',
          })}
          id="mobile-nav"
          onTransitionEnd={e => {
            const target = e.target as HTMLElement
            // prevent triggering for child elements
            if (target.id !== 'mobile-nav') {
              return
            }
            setNavTransitionDirection(prev => (prev === 'enter' ? 'exit' : 'enter'))

            // Ensures both the main menu and submenu animate out together
            if (!navOpen) {
              setNavValue('')
            }
          }}
          aria-hidden={!navOpen}
          orientation="vertical"
        >
          <RemoveScroll enabled={navOpen}>
            <NavigationMenu.List className="flex h-screen-without-navbar min-h-full flex-col justify-between overflow-x-auto bg-surface-elevated">
              <div className={accordion({className: 'mx-s5 border-border-subdued'})}>
                {navigationItems.map((navigationItem, index) => {
                  if (navigationItem) {
                    if ('href' in navigationItem) {
                      return (
                        <HgNavigationItemLink
                          tabIndex={navOpen ? 0 : -1}
                          key={index}
                          {...navigationItem}
                        />
                      )
                    }
                    if ('menuTitle' in navigationItem) {
                      return (
                        <NavigationMenu.Item
                          key={index}
                          className={accordionItem({
                            className: 'border-border-subdued',
                          })}
                        >
                          <NavigationMenu.Trigger
                            {...getPreventHoverProps()}
                            className={accordionTrigger({
                              className: 'flex w-full justify-between',
                            })}
                            tabIndex={navOpen ? 0 : -1}
                            onFocus={() => {
                              setNavValue('')
                            }}
                          >
                            {navigationItem.menuTitle}
                            <HgIcon iconType="chevron-right" size="medium" />
                          </NavigationMenu.Trigger>
                          <HgMobileContent
                            menuTitle={navigationItem.menuTitle}
                            subMenus={navigationItem.subMenus}
                            setNavValue={setNavValue}
                            closeNav={closeNav}
                            footerProps={navigationItem.footerProps}
                          />
                        </NavigationMenu.Item>
                      )
                    }
                  }
                  return null
                })}
              </div>
              <div className="flex w-full gap-s2 px-s5 py-s7 md:hidden">
                <CTAButtons
                  primaryButtonProps={primaryButtonProps}
                  tabIndex={navOpen ? 0 : -1}
                />
              </div>
            </NavigationMenu.List>
            <NavigationMenu.Viewport
              className={content({disableAnimation: !navOpen})}
              {...getPreventHoverProps()}
            />
          </RemoveScroll>
        </NavigationMenu.Root>
      </div>
    </>
  )
}
