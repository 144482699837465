'use client'

import _ from 'lodash'
import {useCallback, useEffect, useLayoutEffect, useState} from 'react'
import {HgDesktopNav} from './_components/HgDesktopNav'
import HgMobileNav from './_components/HgMobileNav'
import {type HgNavbarProps} from './types'
import NavbarThemeSelector from './_components/NavbarThemeSelector'
import {NAVBAR_CONTAINER_ID} from './constants'
import {cn} from '~/design-system/utils'

const HgNavbar = ({
  navigationItems,
  primaryButtonProps,
  handleDownloadAnalyticsEvent,
  hideEscapeLinks,
  showDropShadow = true,
  className,
}: HgNavbarProps) => {
  const [isScrolled, setIsScrolled] = useState(false)

  const checkIfNavbarIsScrolled = useCallback(() => {
    const navbar = document.getElementById(NAVBAR_CONTAINER_ID)
    if (navbar) {
      const {top} = navbar.getBoundingClientRect()
      // Mobile safari can return float values for getBoundingClientRect()
      const hasScrolled = Number(window.scrollY) > top && Math.floor(top) <= 0
      setIsScrolled(hasScrolled)
    }
  }, [])

  useLayoutEffect(() => {
    // check if navbar is initially scrolled
    checkIfNavbarIsScrolled()
  }, [checkIfNavbarIsScrolled])

  useEffect(() => {
    const throttledCheckIfNavbarIsScrolled = _.throttle(
      checkIfNavbarIsScrolled,
      50, // it's ok to delay "scrolled navbar" animation slightly
      {leading: true}
    )

    window.addEventListener('scroll', throttledCheckIfNavbarIsScrolled)

    return () => {
      throttledCheckIfNavbarIsScrolled.cancel()
      window.removeEventListener('scroll', throttledCheckIfNavbarIsScrolled)
    }
  }, [checkIfNavbarIsScrolled])

  return (
    <NavbarThemeSelector
      id={NAVBAR_CONTAINER_ID}
      className={cn('h-navbar-height', className)}
    >
      <HgDesktopNav
        className="hidden lg:flex"
        navigationItems={navigationItems}
        primaryButtonProps={primaryButtonProps}
        handleDownloadAnalyticsEvent={handleDownloadAnalyticsEvent}
        isScrolled={isScrolled}
        showDropShadow={showDropShadow}
        hideEscapeLinks={hideEscapeLinks}
      />
      <HgMobileNav
        className="lg:hidden"
        navigationItems={navigationItems}
        primaryButtonProps={primaryButtonProps}
        handleDownloadAnalyticsEvent={handleDownloadAnalyticsEvent}
        isScrolled={isScrolled}
        showDropShadow={showDropShadow}
        hideEscapeLinks={hideEscapeLinks}
      />
    </NavbarThemeSelector>
  )
}

export default HgNavbar
