import {Input, type InputProps} from '~/design-system/foundations'
import {cn} from '~/design-system/utils'
import {HgButton, HgButtonLink, type HgButtonLinkProps} from '../HgButton'

type HgBlogSearchInputProps = {
  buttonLinkProps: HgButtonLinkProps
  onClear: () => void
} & InputProps

const HgBlogSearchInput = ({
  ref,
  buttonLinkProps,
  className,
  onClear,
  ...inputProps
}: HgBlogSearchInputProps) => {
  return (
    <div className="flex justify-between border-b border-border-subdued bg-inherit focus-within:border-border-default hover:border-border-default has-[input:placeholder-shown:not(:hover)]:border-border-subdued">
      <div className="flex-1">
        <label htmlFor={inputProps.id} className="sr-only">
          Search
        </label>
        <Input
          ref={ref}
          className={cn(
            'w-full bg-inherit text-text-default arcadia-subheading-2 selection:bg-border-focus selection:text-text-on-primary placeholder:text-text-disabled focus-visible:outline-none',
            className
          )}
          {...inputProps}
        />
      </div>
      <HgButton
        className="aria-hidden:invisible"
        aria-hidden={!inputProps.value}
        variant="text"
        contentType="icon"
        iconProps={{iconType: 'xmark'}}
        size="xl"
        aria-label="Clear Search"
        onClick={onClear}
      />
      <HgButtonLink {...buttonLinkProps} />
    </div>
  )
}

export default HgBlogSearchInput
