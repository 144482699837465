'use client'

import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import {tv} from 'tailwind-variants'
import {
  HgNavigationItemLink,
  HgNavigationMenu,
  HgNavigationViewport,
} from '~/design-system/hg/components'
import {type HgDesktopNavProps} from '../types'
import CTAButtons from './CTAButtons'
import HgNavigationWordmark from './HgNavigationWordmark'

const navbarVariants = tv({
  base: 'center relative z-navbar-z-index m-0 flex h-navbar-height min-h-navbar-height w-screen list-none flex-row items-center justify-between bg-transparent-transparent px-s5 py-16 transition-colors duration-300',
  variants: {
    isScrolled: {
      true: 'bg-background-default backdrop-blur-[10px]',
    },
    showDropShadow: {
      true: '',
    },
  },
  compoundVariants: [
    {
      isScrolled: true,
      showDropShadow: true,
      className: 'shadow-[0px_0px_6px_0px_rgba(86,86,118,0.10)]',
    },
  ],
})

export const HgDesktopNav = ({
  navigationItems,
  primaryButtonProps,
  handleDownloadAnalyticsEvent,
  isScrolled,
  showDropShadow,
  className,
  hideEscapeLinks,
}: HgDesktopNavProps) => {
  return (
    <NavigationMenu.Root
      className={navbarVariants({isScrolled, className, showDropShadow})}
    >
      <div className="me-s2 flex grow basis-0 items-center">
        <HgNavigationWordmark
          handleDownloadAnalyticsEvent={handleDownloadAnalyticsEvent}
        />
      </div>
      {!hideEscapeLinks && (
        <div className="flex">
          <NavigationMenu.List className="flex items-center space-x-s2">
            {navigationItems.map((navigationItem, index) => {
              if (navigationItem) {
                if ('href' in navigationItem) {
                  return <HgNavigationItemLink key={index} {...navigationItem} />
                }
                if ('menuTitle' in navigationItem) {
                  return <HgNavigationMenu key={index} {...navigationItem} />
                }
              }
              return null
            })}
          </NavigationMenu.List>
        </div>
      )}
      <div className="ms-s2 flex grow basis-0 justify-end">
        <NavigationMenu.List className="flex items-center">
          <CTAButtons primaryButtonProps={primaryButtonProps} />
        </NavigationMenu.List>
      </div>
      <HgNavigationViewport />
    </NavigationMenu.Root>
  )
}
