'use client'

import {kebabCase} from 'lodash'
import {useEffect} from 'react'
import {type SingleLineStructuredTextFragment} from '~/cms/components/SingleLineStructuredText/_generated/fragment.graphql'
import {useDisclaimerContext} from '~/cms/pages/CMSPageLayout/_components/DisclaimerProvider'
import {
  DISCLAIMER_SECTION_ID,
  MAIN_CONTENT_ID,
} from '~/cms/pages/CMSPageLayout/_components/Disclaimers'

type DisclaimerLinkProps = {
  id: string
  text: SingleLineStructuredTextFragment['text']
  label: string
}

export default function DisclaimerLink({id, text, label}: DisclaimerLinkProps) {
  const kebabLabel = kebabCase(label)
  const {addDisclaimer, disclaimers} = useDisclaimerContext()

  useEffect(() => {
    addDisclaimer({
      id,
      label,
      text,
      kebabLabel,
    })
  }, [addDisclaimer, id, kebabLabel, label, text])

  const handleClick = () => {
    // NB: In order to achieve the parallax effect on the disclaimer section, it's position may be fixed,
    // meaning that scrolling to it won't work unless we update the position to be static before scrolling
    // to it.
    const disclaimerSection = document.getElementById(DISCLAIMER_SECTION_ID)
    const mainElement = document.getElementById(MAIN_CONTENT_ID)

    if (!disclaimerSection || !mainElement) return
    disclaimerSection.style.position = 'static'
    mainElement.style.marginBottom = '0'
  }

  return (
    <a
      onClick={handleClick}
      aria-describedby={`footnote-${kebabLabel}`}
      href={`#footnote-${kebabLabel}`}
      id={`disclaimer-${kebabLabel}`}
      className="outline-2 outline-border-focus focus-visible:outline"
    >
      <sup>{disclaimers[id]?.number}</sup>
    </a>
  )
}
