import {HgTextLink, HgHamburger, HgButton} from '~/design-system/hg/components'
import {cn} from '~/design-system/utils'
import {internalLinks} from '~/routing/internal-links'
import mobileNavVariants from '../../HgNavbar/_variants/mobileNavVariants'
import {type HgBlogNavbarProps} from '../types'
import {useBlogNavbarContext} from './HgBlogNavbarProvider'
import HgBlogNavSearch from './HgBlogNavSearch'
import HgBlogNavbarMenu from './HgBlogNavbarMenu'
import HgChevronIndicator from '~/design-system/hg/components/HgNavigation/HgChevronIndicator'

export default function HgBlogNav({
  className,
  isScrolled,
  ...props
}: HgBlogNavbarProps & {className?: string; isScrolled?: boolean}) {
  const {
    toggleSearch,
    toggleNav,
    state: {navOpen, isResizing},
  } = useBlogNavbarContext()

  const {bar} = mobileNavVariants({
    navOpen,
    disableAnimation: isResizing,
    isScrolled,
  })

  return (
    <nav
      className={bar({
        className: cn(
          'w-screen border-y border-border-frosted px-s5 py-s2',
          className
        ),
      })}
    >
      <HgTextLink
        variant="revealUnderline"
        href={internalLinks.blogHomepage}
        className="arcadia-ui-2"
      >
        Blog
      </HgTextLink>
      <ul className="hidden gap-s6 arcadia-ui-2 xl:flex">
        {props.categoryLinks.categories.map(category => (
          <li key={category.name}>
            <HgTextLink variant="revealUnderline" href={category.href}>
              {category.name}
            </HgTextLink>
          </li>
        ))}
      </ul>
      <div className="flex items-center gap-s1 xl:gap-0">
        <HgButton
          variant="text"
          size="small"
          onClick={toggleNav}
          className="group hidden gap-4 xl:flex"
          data-state={navOpen ? 'open' : 'closed'}
        >
          Topics
          <HgChevronIndicator />
        </HgButton>
        <HgButton
          onClick={toggleSearch}
          iconProps={{iconType: 'search'}}
          contentType="icon"
          aria-label="Search"
          variant="text"
          size="small"
        />
        <button onClick={toggleNav} className="xl:hidden">
          <span className="sr-only">{navOpen ? 'Close Menu' : 'Open Menu'}</span>
          <HgHamburger navOpen={navOpen} />
        </button>
      </div>
      <HgBlogNavSearch />
      <HgBlogNavbarMenu {...props} />
    </nav>
  )
}
