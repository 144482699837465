'use client'

import _ from 'lodash'
import {useCallback, useEffect, useLayoutEffect, useState} from 'react'
import HgBlogNav from './_components/HgBlogNav'
import HgBlogNavbarProvider from './_components/HgBlogNavbarProvider'
import {type HgBlogNavbarProps} from './types'
import NavbarThemeSelector from '../HgNavbar/_components/NavbarThemeSelector'
import {NAVBAR_CONTAINER_ID} from '../HgNavbar/constants'
import {BLOG_NAVBAR_CONTAINER_ID} from './constants'

export default function HgBlogNavbar(props: HgBlogNavbarProps) {
  const [isScrolled, setIsScrolled] = useState(false)

  const checkIfNavbarIsScrolled = useCallback(() => {
    const navbar = document.getElementById(NAVBAR_CONTAINER_ID)
    if (navbar) {
      const {top} = navbar.getBoundingClientRect()
      // Mobile safari can return float values for getBoundingClientRect()
      const hasScrolled = Number(window.scrollY) > top && Math.floor(top) <= 0
      setIsScrolled(hasScrolled)
    }
  }, [])

  useLayoutEffect(() => {
    // check if navbar is initially scrolled
    checkIfNavbarIsScrolled()
  }, [checkIfNavbarIsScrolled])

  useEffect(() => {
    const throttledCheckIfNavbarIsScrolled = _.throttle(
      checkIfNavbarIsScrolled,
      50, // it's ok to delay "scrolled navbar" animation slightly
      {leading: true}
    )

    window.addEventListener('scroll', throttledCheckIfNavbarIsScrolled)

    return () => {
      throttledCheckIfNavbarIsScrolled.cancel()
      window.removeEventListener('scroll', throttledCheckIfNavbarIsScrolled)
    }
  }, [checkIfNavbarIsScrolled])

  return (
    <NavbarThemeSelector id={BLOG_NAVBAR_CONTAINER_ID}>
      <HgBlogNavbarProvider>
        <HgBlogNav {...props} isScrolled={isScrolled} />
      </HgBlogNavbarProvider>
    </NavbarThemeSelector>
  )
}
