// Workaround to prevent the default behavior of opening on hover

import {type PointerEventHandler} from 'react'

// https://github.com/radix-ui/primitives/issues/1630
export function getPreventHoverProps() {
  const preventHover: PointerEventHandler<HTMLElement> = event => {
    event.preventDefault()
  }

  return {
    onPointerEnter: preventHover,
    onPointerLeave: preventHover,
    onPointerMove: preventHover,
  }
}
