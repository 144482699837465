import classnames from 'classnames'
import {MercuryIsNotABankDisclaimer} from '~/components/Disclaimer'
import MWDSLink from '~/mercuryWebCompat/design-system/MWDSLink'
import {externalLinks} from '~/routing/external-links'
import {internalLinks} from '~/routing/internal-links'
import MercuryLogo from './_images/mercury-currentColor.svg'
import Wordmark from './_images/wordmark.svg'
import styles from './styles.module.css'

type IFooterProps = {
  noBorder?: boolean
  hideEscapeLinks?: boolean
  noDisclaimer?: boolean
  extraDisclaimers?: React.ReactNode | React.ReactNode[]
  newStyles?: boolean
  className?: string
}

const renderSpanOrJSXElement = (stringOrElement: React.ReactNode) => {
  return typeof stringOrElement === 'string' ? (
    <span>{stringOrElement}</span>
  ) : (
    <>{stringOrElement}</>
  )
}

export default function Footer({
  noBorder = false,
  noDisclaimer = false,
  hideEscapeLinks,
  extraDisclaimers,
  newStyles,
  className,
}: IFooterProps) {
  const renderSectionHeader = (title: string) => (
    <div className={newStyles ? 'mb-12 arcadia-heading-9' : styles.sectionHeader}>
      {title}
    </div>
  )
  const renderInternalLink = (label: string, href: string) => (
    <MWDSLink
      underline="onHover"
      href={href}
      wrapperClassName={styles.linkWrapper}
      analyticsEvent={{
        type: 'fem-footer-link',
        customProperties: {
          label,
        },
      }}
    >
      {label}
    </MWDSLink>
  )

  const renderExternalLink = (label: string, href: string) => (
    <MWDSLink
      newTab
      underline="onHover"
      href={href}
      analyticsEvent={{
        type: 'fem-footer-link',
        customProperties: {
          label,
        },
      }}
      wrapperClassName={styles.linkWrapper}
    >
      {label}
    </MWDSLink>
  )

  return (
    <footer
      className={classnames(
        newStyles ? styles.newStyles : styles.footer,
        !noBorder && styles.footerBorder,
        className
      )}
    >
      <div className={newStyles ? '' : styles.footerContent}>
        <div className={newStyles ? 'arcadia-body-2' : styles.wrapper}>
          {!hideEscapeLinks ? (
            <>
              {!newStyles && (
                <div className={styles.logoContainer}>
                  <MWDSLink
                    aria-label="Mercury homepage"
                    color="black"
                    underline="never"
                    className={styles.logo}
                    href={internalLinks.home}
                  >
                    <MercuryLogo />
                    <Wordmark />
                  </MWDSLink>
                </div>
              )}
              <div
                className={
                  newStyles
                    ? 'grid grid-cols-2 gap-x-32 gap-y-s9 sm:grid-cols-3'
                    : styles.linkGrid
                }
              >
                <div className={styles.linkGridSection}>
                  {renderSectionHeader('Account')}
                  {renderInternalLink('Open Account', externalLinks.auth.signup)}
                  {renderInternalLink('Log In', externalLinks.auth.login)}
                  {renderExternalLink('iOS App', externalLinks.mobileApps.iOS)}
                  {renderExternalLink(
                    'Android App',
                    externalLinks.mobileApps.android
                  )}
                </div>
                <div className={styles.linkGridSection}>
                  {renderSectionHeader('Products')}
                  {renderInternalLink(
                    'Business Checking & Savings',
                    internalLinks.bankAccounts
                  )}
                  {renderInternalLink('Corporate Credit Card', internalLinks.credit)}
                  {renderInternalLink('Treasury', internalLinks.treasury)}
                  {renderInternalLink('Venture Debt', internalLinks.ventureDebt)}
                  {renderInternalLink('SAFEs', internalLinks.safe)}
                  {renderInternalLink('Bill Pay', internalLinks.billPay)}
                  {renderInternalLink(
                    'Accounting Automations',
                    internalLinks.accountingAutomations
                  )}
                  {renderInternalLink(
                    'Personal Banking',
                    internalLinks.personalBanking
                  )}
                </div>
                <div className={styles.linkGridSection}>
                  {renderSectionHeader('Platform')}
                  {renderExternalLink('Explore Demo', externalLinks.demo)}
                  {renderInternalLink('Pricing', internalLinks.pricing)}
                  {renderInternalLink(
                    'Financial Workflows',
                    internalLinks.financialWorkflows
                  )}
                  {renderInternalLink('Security', internalLinks.security)}
                  {renderInternalLink('API Access', internalLinks.api)}
                  {renderInternalLink('How Mercury Works', internalLinks.howItWorks)}
                  {renderInternalLink('Help Center', externalLinks.helpCenter.index)}
                </div>
                <div className={styles.linkGridSection}>
                  {renderSectionHeader('Industries')}
                  {renderInternalLink('SaaS', internalLinks.saas)}
                  {renderInternalLink('Ecommerce', internalLinks.ecommerce)}
                  {renderInternalLink('VC Funds', internalLinks.vcFunds)}
                  {renderInternalLink('Web3', internalLinks.web3)}
                  {renderInternalLink('Life Science', internalLinks.lifeScience)}
                </div>
                <div className={styles.linkGridSection}>
                  {renderSectionHeader('Grow')}
                  {renderInternalLink('Mercury Raise', internalLinks.raise)}
                  {renderInternalLink(
                    'Investor Connect',
                    internalLinks.raiseInvestorConnect
                  )}
                  {renderInternalLink(
                    'Investor Database',
                    internalLinks.investorIndex
                  )}
                  {renderInternalLink(
                    'Founder Community',
                    internalLinks.raiseFounderCommunity
                  )}
                  {renderInternalLink('Partnerships', internalLinks.partnerships)}
                  {renderInternalLink('Partner Perks', internalLinks.perks)}
                </div>
                <div className={styles.linkGridSection}>
                  {renderSectionHeader('Learn')}
                  {renderExternalLink('Blog', externalLinks.blog.index)}
                  {renderExternalLink('Events', externalLinks.events)}
                  {renderExternalLink('Meridian', externalLinks.meridian.index)}
                  {renderInternalLink(
                    'Software Stack',
                    internalLinks.softwareStackIndex
                  )}
                </div>
                <div className={styles.linkGridSection}>
                  {renderSectionHeader('Company')}
                  {renderInternalLink('About', internalLinks.about)}
                  {renderInternalLink('Jobs', internalLinks.jobs)}
                  {renderInternalLink('FAQ', internalLinks.faq)}
                  {renderInternalLink('Legal', internalLinks.legal.terms)}
                  {renderInternalLink('Privacy Policy', internalLinks.legal.privacy)}
                  {renderInternalLink('Contact Us', internalLinks.contact)}
                </div>

                <div className={styles.linkGridSection}>
                  {renderSectionHeader('Follow us')}
                  {renderExternalLink('Twitter', externalLinks.social.twitter)}
                  {renderExternalLink('AngelList', externalLinks.social.angel)}
                  {renderExternalLink('LinkedIn', externalLinks.social.linkedin)}
                  {renderExternalLink('YouTube', externalLinks.social.youtube)}
                </div>
              </div>
            </>
          ) : (
            <>
              <MercuryLogo
                alt="Mercury logo"
                role="presentation"
                height={33}
                width={32}
              />
              <div className={styles.linkGrid}>
                <div className={styles.linkGridSection}>
                  {renderSectionHeader('Links')}
                  {renderInternalLink('Open Account', externalLinks.auth.signup)}
                  {renderInternalLink('Log In', externalLinks.auth.login)}
                </div>
              </div>
            </>
          )}
        </div>
        {!noDisclaimer && (
          <div className={styles.disclaimerContainer}>
            <span>
              *<MercuryIsNotABankDisclaimer />
            </span>
            {extraDisclaimers &&
              (Array.isArray(extraDisclaimers)
                ? extraDisclaimers.map(renderSpanOrJSXElement)
                : renderSpanOrJSXElement(extraDisclaimers))}
          </div>
        )}
      </div>
    </footer>
  )
}
